import { EditorSDK } from '@wix/platform-editor-sdk';
import { SANTA_MEMBERS_APP_DEF_ID } from '../constants/apps';

interface SantaMembersAppApi {
  setSidebarLayout(): Promise<void>;
  setHorizontalLayout(): Promise<void>;
}

export const createAppAPI = (editorSDK: EditorSDK) => ({
  setSidebarLayout: async () => {
    const santaMembersApi = (await editorSDK.application.getPublicAPI('', {
      appDefinitionId: SANTA_MEMBERS_APP_DEF_ID
    })) as SantaMembersAppApi;

    await santaMembersApi.setSidebarLayout();
  },
  setHorizontalLayout: async () => {
    const santaMembersApi = (await editorSDK.application.getPublicAPI('', {
      appDefinitionId: SANTA_MEMBERS_APP_DEF_ID
    })) as SantaMembersAppApi;

    await santaMembersApi.setHorizontalLayout();
  }
});
